// DashboardPanelItems.tsx
import { DashboardPanelItem } from "./DashboardPanelItem";
import { formatCurrency } from "./FormatCurrency";
import {IOrderStatistics} from "../../Interfaces";

interface DashboardPanelItemsProps {
    data: IOrderStatistics | undefined;
}

export const DashboardPanelItems: React.FC<DashboardPanelItemsProps> = ({ data }) => (
    <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <DashboardPanelItem
                heading="Count completed today"
                data={data?.orderTodayCount}
            />
            <DashboardPanelItem
                heading="Order Count last 7 days"
                data={data?.ordersWithinSevenDaysCount}
            />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <DashboardPanelItem
                heading="Daily order value"
                data={formatCurrency(data?.orderSumTotalToday)}
            />
            <DashboardPanelItem
                heading="Order value last 7 days"
                data={formatCurrency(data?.orderSumTotalLastSevenDays)}
            />
        </div>
    </div>
);
