// OrdersDashPanel.tsx
import { useApiUrl, useCustom } from "@pankod/refine-core";
import { IOrderStatistics } from "Interfaces";
import { Fragment, useState } from "react";
import { Button } from "@pankod/refine-antd";
import { SyncOrderModal } from "./SyncOrderModal";
import { DashboardPanelItems } from "./DashboardPanelItems";

export const OrdersDashPanel = () => {
  const apiUrl = useApiUrl();
  const { data } = useCustom<IOrderStatistics>({
    url: `${apiUrl}/dashboard/orderstatistics`,
    method: "get",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
      <Fragment>
        <DashboardPanelItems data={data?.data} />
        {/*<div>*/}
        {/*  <Button onClick={() => setIsModalVisible(true)}>Sync Order</Button>*/}
        {/*  <SyncOrderModal*/}
        {/*      apiUrl={apiUrl}*/}
        {/*      isVisible={isModalVisible}*/}
        {/*      onClose={() => setIsModalVisible(false)}*/}
        {/*  />*/}
        {/*</div>*/}
      </Fragment>
  );
};
