import dayjs, {Dayjs} from "dayjs";
import React, {useEffect} from "react";
import {Button, Col, Form, FormProps, Icons, Input, Row, Select, Space, DatePicker} from "@pankod/refine-antd";
import {dateTimeOffsetMelbourneHelper} from "../../Services/dateTimeOffsetMelbourneHelper";
import {
    DATE_SEARCH_RANGES,
    DATE_SEARCH_RANGES_CUSTOM,
    DATE_SEARCH_RANGES_LAST_3_DAYS,
    DATE_SEARCH_RANGES_LAST_7_DAYS,
    DATE_SEARCH_RANGES_TODAY,
    DATE_SEARCH_RANGES_YESTERDAY
} from "../../constants";


const {RangePicker} = DatePicker;

const OrderFilter: React.FC<{ formProps: FormProps, reset: () => void; }> = ({formProps, reset}) => {
    const handleDateRange = (value) => {
        // this returns an object of Dayjs's with some standard offsets set to melbourne time
        // there are more ranges available
        const {startOfToday, startOfYesterday, startOf3DaysAgo, startOf7DaysAgo} =
            dateTimeOffsetMelbourneHelper();

        let newDates: [Dayjs, Dayjs];

        switch (value) {
            case DATE_SEARCH_RANGES_TODAY:
                newDates = [startOfToday, startOfToday];
                break;
            case DATE_SEARCH_RANGES_YESTERDAY:
                newDates = [startOfYesterday, startOfYesterday];
                break;
            case DATE_SEARCH_RANGES_LAST_3_DAYS:
                newDates = [startOf3DaysAgo, startOfToday];
                break;
            case DATE_SEARCH_RANGES_LAST_7_DAYS:
                newDates = [startOf7DaysAgo, startOfToday];
                break;
            case DATE_SEARCH_RANGES_CUSTOM:
                newDates = undefined;
                break;
            default:
                console.warn(
                    `Handle Date range - Unkown date value selected '${value}' `
                );
        }

        formProps.form.setFieldsValue({
            orderDate: newDates,
        });
    };


    useEffect(() => {
        // const fieldsValue = formProps.form.getFieldsValue()
        const savedFilter = localStorage.getItem('savedOrderFilters');
        let fieldsValue = savedFilter ? JSON.parse(savedFilter) : { /* default filter state */};
        console.log("IN", fieldsValue)

        fieldsValue.orderDate = fieldsValue?.orderDate?.map(one => {
            return dayjs(one)
        })

        formProps.form.setFieldsValue(fieldsValue);
        formProps.form.submit()

    }, []);

    // useEffect(() => {
    //     const fieldsValue = formProps.form.getFieldsValue()
    //     console.log("SAVING", fieldsValue)
    //     localStorage.setItem('savedOrderFilters', JSON.stringify(fieldsValue));
    // }, [formProps.form]);

    return (
        <>
            <Form layout="vertical" {...formProps}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Space align="end" size={"large"}>
                            <Form.Item label="Multi Search" name="generalSearch"
                                // valuePropName="generalSearch"
                                // getValueProps={(value) => ({
                                //     value: "HI",
                                // })}
                            >
                                <Input
                                    style={{width: "300px"}}
                                    allowClear
                                    placeholder="Search Order Ids, Status, etc..."
                                    prefix={<Icons.SearchOutlined/>}
                                />
                            </Form.Item>

                            <Form.Item label="Ship method search" name="shipMethodSearch">
                                <Input
                                    style={{width: "150px"}}
                                    allowClear
                                    placeholder="Search Shipment method"
                                    prefix={<Icons.FileSearchOutlined/>}
                                />
                            </Form.Item>

                            <Form.Item label="Search between Date range" name="orderDate">
                                <RangePicker/>
                            </Form.Item>

                            <Form.Item label="Search by Date Range" name="orderDateRange">
                                <Select
                                    onChange={handleDateRange}
                                    style={{width: "180px"}}
                                    allowClear
                                    options={DATE_SEARCH_RANGES}
                                    placeholder="Pick a range"
                                />
                            </Form.Item>

                            {/*<Form.Item label="Search by Value Range" name="orderValues">*/}
                            {/*    <Select*/}
                            {/*        style={{width: "260px"}}*/}
                            {/*        allowClear*/}
                            {/*        options={ORDER_VALUE_RANGES}*/}
                            {/*        placeholder="Order value range"*/}
                            {/*    />*/}
                            {/*</Form.Item>*/}

                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Filter
                                </Button>
                                <Button
                                    type="default"
                                    style={{
                                        marginLeft: "4px"
                                    }}
                                    onClick={() => {
                                        formProps.form.resetFields()
                                        localStorage.setItem('savedOrderFilters', "{}");
                                        reset()
                                        formProps.form.submit()

                                    }}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>

        </>

    );
};

export default OrderFilter;
