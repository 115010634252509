const axios = require('axios');

const { REACT_APP_API_URL, REACT_APP_STRAPI_TOKEN_KEY } = process.env;

const getAxiosConfig = () => {
    const token = localStorage.getItem(`${REACT_APP_STRAPI_TOKEN_KEY}`);
    if (!token) {
        return {};
    }
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export function processRefund(data){
    return axios.post(`${REACT_APP_API_URL}/checkouts/processrefund`, data, {
        ...getAxiosConfig(),
    });
}
