import {Card, Col, List, Row} from "@pankod/refine-antd";
import {
    IResourceComponentsProps,
} from "@pankod/refine-core";
import {getProductBySku, pushToAlgolia, uploadProductCoverImage} from "Services/productServices";

import React, {useEffect, useState, useCallback} from 'react'
import ReactDOM from 'react-dom'
import {useDropzone} from 'react-dropzone'
import toast from "react-hot-toast";

import * as _ from 'underscore'

interface ImageState{
    status:"pending"|"success"|"error",
    message:string
}
interface ProductImageStatus {
    [key: string]:ImageState
}

export const UploadProductImages: React.FC<IResourceComponentsProps> = () => {

    const CHUNK_SIZE = 4;

    const [productImages, setProductImages] = useState<ProductImageStatus>({});

    // const [skus,setSkus] = useState<string[]>([])

    const [currentSku,setCurrentSku] = useState<string>("")

    useEffect(() => {
        const pushToAlgolia = async (sku: string)=>{
            await manualPushToAlgolgia(sku)
        }

        if(currentSku === ""){
            return
        }

        pushToAlgolia(currentSku)
    }, [currentSku]);



    const onDrop = useCallback(async acceptedFiles => {
        const images:ProductImageStatus  = {}
        setProductImages(
            x => { return {}}
        )

        for (const one of acceptedFiles) {
            images[one.name] = {
                status: "pending",
                message: "Uploading"
            }
        }

        console.log(images)
        setProductImages(x => images)
        console.log('productImages', productImages)

        //Chunk acceptedFiles into groups of 4
        const chunks = [];
        for (let i = 0; i < acceptedFiles.length; i += CHUNK_SIZE) {
            chunks.push(acceptedFiles.slice(i, i + CHUNK_SIZE));
        }

        //clear the skus
        // setSkus(x=>[])

        for (const oneChunk of chunks) {
            // Do something with the files
            for (const currentFile of oneChunk) {
                try {
                    await uploadFile(currentFile);
                    setProductImages(x=>{
                        return {...x, [currentFile.name]: {
                                status: "success",
                                message: "Uploaded"
                            }}
                    })
                    console.log('uploaded', Object.keys(productImages).length)
                } catch (err) {
                    setProductImages(x=>{
                        return {...x, [currentFile.name]: {
                                status: "error",
                                message: err.message
                            }}
                    })
                }
            }
        }

        // console.log(skus)
        // // Push to Algolia
        // for (const sku of _.uniq(skus)) {
        //     await manualPushToAlgolgia(sku)
        // }
    }, [])

    const manualPushToAlgolgia = (idToEdit: string) => {
        console.log('pushing to algolia',idToEdit)
        return toast.promise(pushToAlgolia(idToEdit), {
            loading: "Pushing product to Algolia",
            success: "Product pushed to Algolia",
            error: (error) => `${error}`,
        });
    };


    const uploadFile = async (file: File) => {

        // Get the left part of the file name up to the first "."
        const sku = file.name.split(".")[0];
        console.log('sku', sku)

        setCurrentSku(sku)

        // Add sku to the list of skus
        // setSkus(x=>[...x,sku])

        // get the product from the api
        const product = await getProductBySku(sku);
        console.log('file', product)
        if (product && product.data && product.data.length > 0) {
            const locatedProduct = product.data[0];
            // Have a product so upload the image
            let formData = new FormData();
            var imageInfo = {
                caption: `${locatedProduct.masterSku}: ${locatedProduct.title || ""}`,
                alternativeText: locatedProduct.title,
                name: file.name
            };
            console.log('imageInfo', imageInfo);
            //add the file to the formData

            //append ref
            formData.append("ref", "product");
            //append refid
            formData.append("refId", locatedProduct.id.toString());
            //append field
            formData.append("field", "coverImage");
            formData.append("fileInfo", JSON.stringify(imageInfo));

            formData.append("files", file, file.name);

            await uploadProductCoverImage(formData)

        } else {
            if (product && product.data && product.data.length === 0) {
                throw new Error(`No product found for sku ${sku}`);
            } else {
                throw new Error(`Error finding product for sku ${sku}`);
            }
        }
    }

    const renderProductImageState = useCallback((imageState:ImageState) => {
        switch (imageState.status) {
            case "pending":
                return <span>⏳ {imageState.message}</span>;
            case "success":
                return <span>✅ {imageState.message}</span>;
            case "error":
                return <span>❌ {imageState.message}</span>;
            default:
                return <span>⏳ {imageState.message}</span>;
        }
    },[])

    const {getRootProps, getInputProps, isDragActive} = useDropzone(
        {
            onDrop,
            accept: {
                'image/jpeg': [],
                'image/png': []
            }
        })

    return (
        <div>
            <List title="Upload Product Images">
                <Card>
                    <Row>
                        <p>Drop the product image files here to automatically upload and link. Files must be in JPG or
                            PNG format, and named so that the sku is the only value up to the first "." in the file name
                            eg: sku.othertext.jpg </p>
                    </Row>
                    <Row>
                        <Col span={24}>

                            <div {...getRootProps()}
                                 className="product-image-drop-target"
                                 style={{
                                     height: "20rem",
                                 }}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <h3 className="product-image-drop-content">Drop the files here ...</h3> :
                                        <h3 className="product-image-drop-content">Drag 'n' drop some files here, or
                                            click to select files</h3>
                                }
                            </div>
                        </Col>
                    </Row>
                    {Object.keys(productImages).length > 0 &&
                    <Row>
                        <Col span={24}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                rowGap: "1rem",
                                columnGap: "1rem",
                                marginTop: "1rem",
                            }}>
                                {Object.keys(productImages).map((key) => {
                                    const one = productImages[key];
                                    return (
                                        <Card key={key}>
                                            <div>{key}</div>
                                            <div>{renderProductImageState(one)}</div>
                                        </Card>
                                    )
                                })}
                            </div>



                        </Col>
                    </Row>
                    }
                </Card>
            </List>
        </div>
    )
}
