import "./styles.css";
import { Authenticated, AuthProvider, Refine } from "@pankod/refine-core";
import {
  ErrorComponent,
  ReadyPage,
  notificationProvider,
  Layout,
  LoginPage,
  Space,
} from "@pankod/refine-antd";
import { RiseOutlined, TagsOutlined, CloudUploadOutlined, LineChartOutlined, ShoppingOutlined, DeliveredProcedureOutlined,PieChartOutlined } from "@ant-design/icons";
import routerProvider from "@pankod/refine-react-router-v6";
import "@pankod/refine-antd/dist/styles.min.css";
//This is the customized version to make draft elemets visible, which strapi hides by default
import { AuthHelper, DataProvider } from "dataProviders/strapi-v3";

import { ProdList } from "Pages/Products/list";
import axios from "axios";
import { ProductEdit } from "Pages/Products/edit";
import { TagList } from "Pages/Tags/list";
import { TagEdit } from "Pages/Tags/Edit/edit";
import { ProdAttributeList } from "Pages/Product_Attributes/list";
import { ProdAttibuteEdit } from "Pages/Product_Attributes/Edit/edit";
import { DashBoard } from "Pages/Dashboard/Dashboard";
import { LogoRender } from "Pages/Sider/LogoRender";
import { OrderList } from "Pages/Orders/list";
import { ShowOrder } from "Pages/Orders/show";
import { SubscriptionList } from "Pages/Subscriptions/list";
import { SubscriptionEdit } from "Pages/Subscriptions/Edit/edit";
import { SubscriptionRunList } from "Pages/Subscription_Run/list";
import { SubscriptionRunEdit } from "Pages/Subscription_Run/edit";
import { SubscriptionRunCreate } from "Pages/Subscription_Run/create";
import { SubscriptionOrderEdit } from "Pages/Subscription_Order/Edit/edit";
import { OrderShippingList } from "Pages/OrderShipping/list";
import { OrderShippingEdit } from "Pages/OrderShipping/Edit/edit";
import { OrderShippingCreate } from "Pages/OrderShipping/create";
import { Toaster } from "react-hot-toast";
import PackProjection from "Pages/PackProjection/PackProjection";
import { Merchandising } from "Pages/Merchandising/Merchandising";
import { UploadProductImages
 } from "Pages/Products/UploadProductImages";
import {CreateOrder} from "./Pages/Orders/create";
import {ProductBundleCreate, ProductBundleEdit, ProductBundleList} from "./Pages/ProductBundle";
const { Link } = routerProvider;

function App() {
  //add in axios
  const { REACT_APP_API_URL, REACT_APP_STRAPI_TOKEN_KEY } = process.env;

  const axiosInstance = axios.create();

  const strapiAuthHelper = AuthHelper(`${REACT_APP_API_URL}`);

  const authProvider: AuthProvider = {
    login: async (params: any) => {
      const log = await strapiAuthHelper.login(
        params.username,
        params.password
      );

      if (log.status === 200) {
        if (log.data.user.role.name === "CMSEditor") {
          localStorage.setItem(`${REACT_APP_STRAPI_TOKEN_KEY}`, log.data.jwt);

          // set header axios instance
          axiosInstance.defaults.headers = {
            Authorization: `Bearer ${log.data.jwt}`,
          };

          localStorage.setItem("username", params.username);
          return Promise.resolve();
        }
      }

      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem(`${REACT_APP_STRAPI_TOKEN_KEY}`);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(`${REACT_APP_STRAPI_TOKEN_KEY}`);
      if (token) {
        axiosInstance.defaults.headers = {
          Authorization: `Bearer ${token}`,
        };
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const token = localStorage.getItem(`${REACT_APP_STRAPI_TOKEN_KEY}`);
      if (!token) {
        return Promise.reject();
      }

      const { data, status } = await strapiAuthHelper.me(token);
      if (status === 200) {
        const { id, username, email } = data;
        return Promise.resolve({
          id,
          username,
          email,
        });
      }

      return Promise.reject();
    },
  };

  const dataProvider = DataProvider(`${`${REACT_APP_API_URL}`}`, axiosInstance);

  const AuthenticatedPackProjectionPage = () => {
    return (
      <Authenticated>
        <PackProjection />
      </Authenticated>
    );
  };
  const AuthenticatedMerchandisingPage = () => {
    return (
      <Authenticated>
        <Merchandising />
      </Authenticated>
    );
  };
  const AuthenticatedUploadProductImagesPage = () => {
    return (
      <Authenticated>
        <UploadProductImages />
      </Authenticated>
    );
  };

  return (
    <Refine
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <AuthenticatedPackProjectionPage />,
            path: "/pack-projection",
            layout: true,
            name: "Forward Pack Projection Table",
          },
          {
            element: <AuthenticatedMerchandisingPage />,
            path: "/merchandising",
            layout: true,
            name: "Merchandising",
          },
          {
            element: <AuthenticatedUploadProductImagesPage />,
            path: "/uploadproductimages",
            layout: true,
            name: "Upload Product Images",
          },
          {
            element: <OrderList/>,
            path: "/orders?mode=cnc",
            layout: true,
            name: "Orders",
          }
        ],
      }}
      authProvider={authProvider}
      notificationProvider={notificationProvider}
      Layout={Layout}
      OffLayoutArea={() => (
        <Toaster
          position="bottom-right"
          toastOptions={{
            success: {
              style: {
                width: "400px",
                padding: "20px",
                height: "80px",
                fontSize: "16px",
              },
              duration: 4000,
            },
            error: {
              style: {
                width: "400px",
                padding: "20px",
                height: "80px",
              },
              duration: 7000,
            },
            loading: {
              style: {
                width: "400px",
                padding: "20px",
                height: "80px",
              },
            },
          }}
        />
      )}
      LoginPage={LoginPage}
      dataProvider={dataProvider}
      ReadyPage={ReadyPage}
      Title={() => (
        <Link
          to="/"
          style={{ float: "left", marginLeft: "25px", marginTop: "15px" }}
        >
          <Space>
            <LogoRender></LogoRender>
          </Space>
        </Link>
      )}
      DashboardPage={DashBoard}
      catchAll={<ErrorComponent />}
      resources={[
        {
          name: "products",
          list: ProdList,
          edit: ProductEdit,
        },
        {
          name: "product-bundles",
          list: ProductBundleList,
          edit: ProductBundleEdit,
          create:ProductBundleCreate,
          icon: <PieChartOutlined />,
        },
        {
          // we need the custom page to be shown in the sidebar but we have to trick it to do so
          // https://refine.dev/docs/advanced-tutorials/custom-pages/
          name: "uploadproductimages",
          list: () => null,
          options: {
            label: "Upload Product Images",
          },
          icon: <CloudUploadOutlined />,
        },        {
          // we need the custom page to be shown in the sidebar but we have to trick it to do so
          // https://refine.dev/docs/advanced-tutorials/custom-pages/
          name: "merchandising",
          list: () => null,
          options: {
            label: "Merchandising",
          },
          icon: <RiseOutlined />,
        },
        {
          name: "tags",
          list: TagList,
          edit: TagEdit,
          icon: <TagsOutlined />,
        },
        {
          name: "product-attributes",
          options: { label: "Product Attributes" },
          list: ProdAttributeList,
          edit: ProdAttibuteEdit,
        },
        {
          name: "checkouts",
          options: { label: "Orders" },
          list: OrderList,
          show: ShowOrder,
          create:CreateOrder,
          icon: <ShoppingOutlined />,
        },
        // {
        //   // we need the custom page to be shown in the sidebar but we have to trick it to do so
        //   // https://refine.dev/docs/advanced-tutorials/custom-pages/
        //   name: "checkouts?mode=cnc",
        //   list: () => <OrderList/>,
        //   options: {
        //     label: "CNC Orders",
        //   },
        //   icon: <ShoppingOutlined />,
        // },
        {
          name: "subscriptions",
          list: SubscriptionList,
          edit: SubscriptionEdit,
        },
        {
          name: "subscription-runs",
          options: {
            label: "Subscription Run",
          },
          list: SubscriptionRunList,
          edit: SubscriptionRunEdit,
          create: SubscriptionRunCreate,
        },
        {
          name: "subscription-orders",
          options: { label: "Sub-Order" },
          edit: SubscriptionOrderEdit,
        },
        {
          name: "order-shippings",
          options: {
            label: "Shipping",
          },
          list: OrderShippingList,
          edit: OrderShippingEdit,
          create: OrderShippingCreate,
          icon: <DeliveredProcedureOutlined />
        },
        {
          // we need the custom page to be shown in the sidebar but we have to trick it to do so
          // https://refine.dev/docs/advanced-tutorials/custom-pages/
          name: "pack-projection",
          list: () => null,
          options: {
            label: "Pack Projection",
          },
          icon: <LineChartOutlined />,
        },
      ]}
    />
  );
}

export default App;
