// FormItems.tsx
import React from "react";
import {DateField, Form, Input, Select} from "@pankod/refine-antd";

export const NameFormItem: React.FC = () => (
    <Form.Item label="Title" name="title">
        <Input />
    </Form.Item>
);

export const CodeFormItem: React.FC = () => (
    <Form.Item label="Flat Price" name="flat_price">
        <Input />
    </Form.Item>
);

export const TagTypeFormItem = ({ selectProps }) => (
    <Form.Item label="Tag Type" name={["tagType", "id"]}>
        <Select {...selectProps} maxTagTextLength={100} />
    </Form.Item>
);

export const CreatedAtFormItem: React.FC = () => (
    <Form.Item label="Created At" name="created_at">
        <DateField format="LLL" value="created_at" />
    </Form.Item>
);

export const UpdatedAtFormItem: React.FC = () => (
    <Form.Item label="Updated At" name="updated_at">
        <DateField format="LLL" value="updated_at" />
    </Form.Item>
);
