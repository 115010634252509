import { useState, useEffect, useCallback } from "react";
import * as _ from "underscore";

export const useSelection = (initialItems = [], initialSelectedItems = []) => {
    const [selectMap, setSelectMap] = useState({});
    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        console.log("initialSelectedItems", initialSelectedItems);

        if(initialSelectedItems.length === 0) {
            return
        }

        if (!_.isEqual(selectedItems, initialSelectedItems)) {
            console.log("selectedItems", selectedItems);
            setSelectedItems(initialSelectedItems);
        }
    }, [initialSelectedItems]);

    const selectAllItems = useCallback((selected, items) => {
        setSelectedItems(selected ? [...items] : []);
    }, []);

    useEffect(() => {
        if (isAllSelected) {
            selectAllItems(true, initialItems);
        }
    }, [isAllSelected, initialItems, selectAllItems]);

    const selectItem = useCallback((item, selected) => {
        setSelectedItems((prevSelectedItems) => {
            if (selected) {
                return [...prevSelectedItems, item];
            } else {
                return prevSelectedItems.filter((i) => i.id !== item.id);
            }
        });
    }, []);

    useEffect(() => {
        const mapKeys = selectedItems.map((item) => item.id);
        setSelectMap(Object.fromEntries(mapKeys.map((key) => [key, true])));
    }, [selectedItems]);

    return {
        selectMap,
        selectedItems,
        setSelectedItems,
        isAllSelected,
        setIsAllSelected,
        selectItem,
    };
};
