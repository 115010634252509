import {
    Row,
    Col,
    Table,
    useTable,
    Card,
    Form,
    List,
    Input,
    Modal,
    useModalForm,
    Select,
    Space,
    EditButton,
    Tag,
    Button,
    useModal, DeleteButton,
} from "@pankod/refine-antd";

import {HttpError, IResourceComponentsProps} from "@pankod/refine-core";
import {DatePicker} from "antd";
import {StatusColours, SUBSCRIPTION_RUN_STATUSES} from "../../constants";

import {IProductBundle, ISubscriptionRun} from "../../Interfaces";
import {Fragment, useEffect} from "react";
import toast from "react-hot-toast";
import {formatCurrency} from "../Dashboard/FormatCurrency";

import {Link} from "react-router-dom";


const dayjs = require("dayjs");
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

export const ProductBundleList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps, tableQueryResult} = useTable<
        IProductBundle,
        HttpError
    >({
        resource: `product-bundles`,
        initialPageSize: 100,
        //i'm temporarily disabling the filter because it is not working properly
        //ive added a method below to filter the results based on the rundate
        // permanentSorter: [
        //   {
        //     field: "runDate",
        //     order: "asc",
        //   },
        // ],
    });

    // Create Modal
    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        // form: createForm,
        // show: createModalShow,
    } = useModalForm<IProductBundle>({
        action: "create",
    });

    const currentRunList = tableQueryResult?.data?.data;

    useEffect(() => {
        if (currentRunList) {
            const openRunData = [];

            currentRunList.forEach((run) => {
                if (run.status === "open") {
                    openRunData.push(run);
                }
            });

            if (openRunData.length > 1) {
                toast.error("Warning: more than one run is open");
            }
        }
    }, [currentRunList]);

    return (
        <Fragment>
            <Card>
                <Row style={{marginBottom: "30px"}}>
                    <Col span={12}></Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <List canCreate={true}>
                            <Table dataSource={tableProps?.dataSource} rowKey="id">
                                <Table.Column<IProductBundle>
                                    dataIndex="title"
                                    title="Title"
                                    render={(_, record) => {
                                        return <Fragment>
                                            {record.title}
                                            {/*<br/>*/}
                                            {/*sku: <Tag key={record.id}>{record.display_product.masterSku}</Tag>*/}
                                        </Fragment>;
                                    }}
                                ></Table.Column>

                                <Table.Column<IProductBundle>
                                    dataIndex="display_product"
                                    title="SKU"
                                    render={(_, record) => {
                                        return (
                                            <Fragment>
                                                <Link to={`/products/edit/${record.display_product.id}`}>
                                                    {record.display_product.masterSku}
                                                </Link>
                                            </Fragment>
                                        );
                                    }}
                                ></Table.Column>

                                <Table.Column<IProductBundle>
                                    dataIndex="products"
                                    title="Products"
                                    render={(_, record) => {
                                        const quantityTable = typeof record.quantityTable === 'string' ? JSON.parse(record.quantityTable) : record.quantityTable ?? {};
                                        // record.products.forEach((product) => {
                                        //     if (!quantityTable[product.masterSku]) {
                                        //         quantityTable[product.masterSku] = 1;
                                        //     }
                                        // });

                                        return <Fragment>
                                            {record.products.map((product, index) => (
                                                <Tag
                                                    key={index}>{product.name} x{quantityTable[product.masterSku] ?? 1}</Tag>
                                            ))}
                                        </Fragment>;
                                    }}
                                ></Table.Column>

                                <Table.Column<IProductBundle>
                                    dataIndex="flat_price"
                                    title="Flat Price"
                                    render={(_, record) => {
                                        return <Fragment> {formatCurrency(record.flat_price)}</Fragment>;
                                    }}
                                ></Table.Column>


                                <Table.Column<IProductBundle>
                                    title="Actions"
                                    dataIndex="actions"
                                    render={(_, record) => (
                                        <Space>
                                            <EditButton size="small" recordItemId={record.id}/>
                                            <DeleteButton size="small" recordItemId={record.id}/>
                                        </Space>
                                    )}
                                />
                            </Table>
                        </List>
                        <Modal {...createModalProps} title="Create a Run">
                            <Form {...createFormProps} layout="vertical">
                                <Form.Item
                                    label="Run Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select options={SUBSCRIPTION_RUN_STATUSES}></Select>
                                </Form.Item>

                                <Form.Item
                                    label="Run Date"
                                    name="runDate"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker/>
                                </Form.Item>
                                <Form.Item
                                    label="openDateTime"
                                    name="openDateTime"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker/>
                                </Form.Item>
                                <Form.Item
                                    label="closeDateTime"
                                    name="closeDateTime"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker/>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};
