import { Tag } from "@pankod/refine-antd";

export const OrderStatusTagRender = ({ status }: { status: string }) => {
  switch (status) {
    case "queued":
      return <Tag color="geekblue">{status}</Tag>;

    case "ordered":
      return <Tag color="green">{status}</Tag>;

    case "orderedintervationrequired":
      return <Tag color="volcano">{status}</Tag>;

    case "manuallyordered":
      return <Tag color="orange">{status}</Tag>;

    case "shipped":
      return <Tag color="gold">{status}</Tag>;

    case "delivered":
      return <Tag color="purple">{status}</Tag>;

    case "cancelled":
      return <Tag color="#eb6200">{status}</Tag>;

    case "failed":
      return <Tag color="#eb0000">{status}</Tag>;

    case "awaitingcollection":
      return <Tag color="#7a7d7b">{status}</Tag>;

    case "orderinterventionrequired":
      return <Tag color="volcano">{status}</Tag>;

    case "partiallyfulfilled":
      return <Tag color="cyan">{status}</Tag>;

    case "fulfilled":
      return <Tag color="green">{status}</Tag>;

    default:
      console.warn(`Order Status - Unknown status value selected '${status}' `);
      return <Tag color="#7a7d7b"> null </Tag>;
  }
};
