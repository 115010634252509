import React, {Fragment, useEffect} from "react";
import {Modal, Card, Table, Checkbox, Space, Button, useTable} from "@pankod/refine-antd";
import {Product} from "Interfaces";
import {ProdFilterMini} from "../../Tags/Edit/ProdFilterMini";
import {PublishedProductStatusIcon} from "../../Products/PublishedProductStatusIcon";
import {ProductStateIconList} from "../../Products/ProductStateIconList";
import {VariantStockOnHand} from "../../Products/VariantStockOnHand";
import {ProductImageThumbnailRender} from "../../Products/ProductImageThumbnailRender";
import {useSelection} from "../hooks/useSelection";
import {CrudFilters, HttpError} from "@pankod/refine-core";
import {PUBLICATION_STATUS_LIVE, PUBLICATION_STATUS_PREVIEW} from "../../../constants";

interface ProductFilterVariables {
    masterStatus: string;
    generalSearch: string;
    childSkus: string;
    productType: string;
    title: string;
    publicationStatus: string;
    filterOutBlankCover: boolean;
    filterOutBlankDesc: boolean;
}

export const ProductModal = ({
                                 modalProps,
                                 closeProductModal,
                                 setSelectedIds: setSelected,
                                 initialSelectedIds,
                             }
) => {


    const {
        tableProps: productTableProps,
        searchFormProps,
        tableQueryResult: productTableQueryResult
    } = useTable<Product, HttpError, ProductFilterVariables>({
        resource: "products",
        initialPageSize: 50,
        permanentFilter: [{field: "_publicationState", operator: "eq", value: "preview"}],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const {
                masterStatus,
                generalSearch,
                productType,
                title,
                publicationStatus,
                filterOutBlankCover,
                filterOutBlankDesc
            } = params;

            if (generalSearch) {
                filters.push({
                    operator: "or",
                    value: [
                        {field: "id", operator: "contains", value: generalSearch},
                        {field: "childSkus", operator: "contains", value: generalSearch},
                        {field: "title", operator: "contains", value: generalSearch},
                    ],
                });
            }

            filters.push(
                {field: "masterStatus", operator: "eq", value: masterStatus},
                {field: "productType", operator: "contains", value: productType},
                {field: "title", operator: "contains", value: title}
            );

            if (filterOutBlankDesc) {
                filters.push({field: "description", operator: "null", value: true});
            } else {
                filters.push({field: "description", operator: "null", value: undefined});
            }

            if (!publicationStatus) {
                filters.push(
                    {field: "_publicationState", operator: "eq", value: "preview"},
                    {field: "published_at", operator: "null", value: undefined}
                );
            } else if (publicationStatus === PUBLICATION_STATUS_PREVIEW) {
                filters.push(
                    {field: "_publicationState", operator: "eq", value: "preview"},
                    {field: "published_at", operator: "null", value: true}
                );
            } else if (publicationStatus === PUBLICATION_STATUS_LIVE) {
                filters.push(
                    {field: "_publicationState", operator: "eq", value: undefined},
                    {field: "published_at", operator: "null", value: undefined}
                );
            } else {
                console.warn(`Product List onSearch. Unknown publicationStatus selected: '${publicationStatus}'`);
            }

            return filters;
        },
    });


    const {
        selectMap,
        selectedItems: selectedProducts,
        isAllSelected,
        setIsAllSelected,
        selectItem: selectProduct,
    } = useSelection([...productTableProps.dataSource ?? []],initialSelectedIds);


    useEffect(() => {
        if (selectedProducts.length === 0) {
            return;
        }
        setSelected(selectedProducts.map((product) => product));
    }, [selectedProducts]);

    return (
        <Modal
            title="Select Products"
            {...modalProps}
            footer={[
                <Button key="close" onClick={closeProductModal}>
                    Close
                </Button>,
            ]}
            width={1000}
        >
            <Card title="Filters">
                <ProdFilterMini formProps={searchFormProps}/>
            </Card>

            <Card title="Selected Products">
                <Fragment>
                    {selectedProducts.map((record) => (
                        <Fragment key={record.id}>
                            <div style={{marginTop: "10px"}}>
                                <PublishedProductStatusIcon productProp={record}/>
                            </div>
                        </Fragment>
                    ))}
                </Fragment>
            </Card>

            <Table {...productTableProps} rowKey="id">
                <Table.Column<Product>
                    dataIndex="meta"
                    title={
                        <Checkbox
                            checked={isAllSelected}
                            onChange={(e) => setIsAllSelected(e.target.checked)}
                        />
                    }
                    render={(_, record) => (
                        <Checkbox
                            checked={selectMap[record.id]}
                            onChange={(e) => selectProduct(record, e.target.checked)}
                        />
                    )}
                />
                <Table.Column dataIndex="id" title="ID" sorter/>
                <Table.Column<Product>
                    dataIndex="title"
                    title="Title"
                    render={(_, record) => (
                        <PublishedProductStatusIcon productProp={record}/>
                    )}
                />
                <Table.Column<Product>
                    title="Quick State"
                    render={(_, record) => (
                        <ProductStateIconList productProp={record}/>
                    )}
                />
                <Table.Column dataIndex="masterSku" title="MasterSku" sorter/>
                <Table.Column dataIndex="productType" title="Product Type" sorter/>
                <Table.Column dataIndex="masterStatus" title="Status" sorter/>
                <Table.Column<Product>
                    title="Variant SOH"
                    align="center"
                    render={(_, record) => (
                        <Space size={"small"}>
                            <VariantStockOnHand variants={record?.variants}/>
                            {record?.variants?.map((v) => v.quantityAvailable).join("/")}
                        </Space>
                    )}
                />
                <Table.Column<Product>
                    title="Cover Image"
                    align="center"
                    render={(_, record) => (
                        <ProductImageThumbnailRender
                            imageUrl={record?.coverImage?.formats?.thumbnail?.url}
                        />
                    )}
                />
            </Table>
        </Modal>
    );
};
