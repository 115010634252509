import { useState, useCallback } from "react";
import { Modal, Button, Input } from "@pankod/refine-antd";
import toast from "react-hot-toast";

interface SyncOrderModalProps {
    apiUrl: string;
    isVisible: boolean;
    onClose: () => void;
}

export const SyncOrderModal: React.FC<SyncOrderModalProps> = ({ apiUrl, isVisible, onClose }) => {
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSyncOrder = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/order/sync`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ orderNumber: inputValue }),
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            }

            await response.json();
            toast.success(`Order synced successfully`);
            onClose();
            setInputValue(""); // Clear the input
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, inputValue, onClose]);

    return (
        <Modal
            title="Sync Order"
            visible={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="sync" type="primary" onClick={handleSyncOrder} loading={loading}>
                    Sync
                </Button>,
            ]}
        >
            <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter order number"
            />
        </Modal>
    );
};
