//this takes formprops and modal props

import {Col, Form, FormProps, Input, Modal, ModalProps, Row,} from "@pankod/refine-antd";
import {IOrder} from "Interfaces";

export const EditShippingModalForm: React.FC<{
    formProps: FormProps;
    modalProps: ModalProps;
    queryData: IOrder | null | undefined;
}> = ({formProps, modalProps, queryData}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    // use custom
    // const { data: productData } = useCustom<Product>({
    //     url: `${apiUrl}/productsbysku/${queryData?.executedSku}`,
    //     method: "get",
    //     queryOptions: {
    //         enabled: !executedeSkuIsUndefined,
    //     },
    // });

    //TODO stop the custome query from being run if the sku is undefined, the above queryOptions isnt working

    return (
        <Modal {...modalProps} title="Edit Shipping">
            <Form {...formProps} layout="vertical" key="sku">

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name={['structuredData', 'shippingAddress', 'addressLine1']}
                                   label="Address Line 1"
                                   style={{width: "350px"}}>
                            <Input defaultValue={queryData?.structuredData?.shippingAddress?.addressLine1}/>
                        </Form.Item>

                        <Form.Item name={['structuredData', 'shippingAddress', 'addressLine2']} style={{width: "350px"}}
                                   label="Address Line 2"
                        >
                            <Input defaultValue={queryData?.structuredData?.shippingAddress?.addressLine2}/>
                        </Form.Item>

                        <Form.Item name={['structuredData', 'shippingAddress', 'addressName']} style={{width: "350px"}}
                                   label="Address Name">
                            <Input defaultValue={queryData?.structuredData?.shippingAddress?.addressName}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name={['structuredData', 'shippingAddress', 'country']} style={{width: "350px"}}
                                   label="Country">
                            <Input defaultValue={queryData?.structuredData?.shippingAddress?.country}/>
                        </Form.Item>

                        <Form.Item name={['structuredData', 'shippingAddress', 'locality']} style={{width: "350px"}}
                                   label="Locality"
                        >
                            <Input defaultValue={queryData?.structuredData?.shippingAddress?.locality}/>
                        </Form.Item>

                        <Form.Item name={['structuredData', 'shippingAddress', 'postcode']} style={{width: "350px"}}
                                   label="Postcode"
                        >
                            <Input defaultValue={queryData?.structuredData?.shippingAddress?.postcode}/>
                        </Form.Item>
                    </Col>
                </Row>


            </Form>
        </Modal>
    );
};
