import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    DateField,
    DatePicker,
    Form,
    Input,
    List,
    Modal,
    Row,
    Select,
    ShowButton,
    Space,
    Table,
    Tag, useModal,
    useModalForm,
    useTable,
} from "@pankod/refine-antd";
import {EditOutlined, PrinterOutlined,RedoOutlined} from '@ant-design/icons';
import {CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCustom,} from "@pankod/refine-core";
import {IOrder, Product} from "Interfaces";
import dayjs, {Dayjs} from "dayjs";

import {
    ORDER_VALUE_RANGES_0_50,
    ORDER_VALUE_RANGES_100_200,
    ORDER_VALUE_RANGES_200_OVER,
    ORDER_VALUE_RANGES_50_100,
    SUBSCRIPTION_RUN_STATUSES,
} from "../../constants";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {OrderStatusTagRender} from "./OrderStatusTagRender";
import {formatCurrency} from "Pages/Dashboard/FormatCurrency";

import * as _ from 'underscore'
import {sendDataToShippit} from "../../Services/shippitServices";
import toast from "react-hot-toast";
import {updateOrderMeta} from "../../Services/productServices";
import {usePDF} from "@react-pdf/renderer";
import {saveAs} from 'file-saver';
import {PdfRenderWrapper} from "./PdfRenderWrapper";
import {sendDataToOptimoRoute} from "../../Services/optimoServices";
import {LabelWrapper} from "./LabelWrapper";
import {getCodeByName} from "../../Utilities/utils";
import {EditShippingModalForm} from "./EditShippingModalForm";
import {SyncOrderModal} from "../Dashboard/SyncOrderModal";
import OrderFilter from "./OrderFilter";

import {useSearchParams} from 'react-router-dom';
import {Tabs} from "antd";
import {RefundModalForm} from "./RefundModalForm";
import {processRefund} from "../../Services/paymentServices";

const {TabPane} = Tabs;

interface OrderFilterVariables {
    generalSearch: string;
    orderDate: [Dayjs, Dayjs];
    orderValues: string;
    orderDateRange: string;
    shipMethodSearch: string;
}


export const OrderList: React.FC<IResourceComponentsProps> = () => {


    const [selectMap, setSelectMap] = useState({})

    const [selectedOrders, setSelectedOrders] = useState([])

    const [exportRecords, setExportRecords] = useState<IOrder[] | undefined>(undefined)
    const [exportLabelRecords, setExportLabelRecords] = useState<IOrder[] | undefined>(undefined)
    const [pdfInstance, updatePdfInstance] = usePDF({document: <PdfRenderWrapper records={exportRecords}/>});
    const [pdfInstanceLabel, updatePdfInstanceLabel] = usePDF({document: <LabelWrapper records={exportRecords}/>});

    const [isAllSelected, setIsAllSelected] = useState(false)

    const apiUrl = useApiUrl();
    const [isModalVisible, setIsModalVisible] = useState(false);


    const [mode, setMode] = useState("normal")

    const [currentRefundId, setCurrentRefundId] = useState<string | undefined>(undefined)


    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        // form: createForm,
        // show: createModalShow,
    } = useModalForm<IOrder>({
        action: "create",
    });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        queryResult: editQueryResult,
        show: editModalShow,
        // form: createForm,
        // show: createModalShow,
    } = useModalForm<IOrder>({
        action: "edit",
    });

    const {
        modalProps: refundModalProps,
        show: refundModalShow,
        close: refundModalClose,
    } = useModal();

    const handleOnClickRefund = (record) => {
        console.log(record)
        setCurrentRefundId(record.uid)
        refundModalShow()
    }

    const handleRefundSuccess = async (data) => {
        refundModalClose()

        await toast.promise(processRefund(
            {
                ...data,
                uid: currentRefundId
            }
        ), {
            loading: `Processing refund for ${currentRefundId}`,
            success: `Refund processed ${currentRefundId}`,
            error: (error) => `${error}`,
        })
            .then(async ()=>{
                const record = tableQueryResult.data.data.find((one) => one.uid === currentRefundId)

                const lastRefund = Number(record.meta?.total_refund ?? 0);
                const currentRefund = lastRefund + Number(data.refundAmount);

                await toast.promise(updateOrderMeta(record.id, 'total_refund',currentRefund ), {
                    loading: `Updating refund logs`,
                    success: `Updated refund logs`,
                    error: (error) => `${error}`,
                })
                    .then(() => {
                        tableQueryResult.refetch()
                    })
            })

    }

    // @ts-ignore
    const {tableProps, searchFormProps, tableQueryResult, setFilters, filters, setSorter} = useTable<
        IOrder,
        HttpError,
        OrderFilterVariables
    >({
        initialPageSize: 50,
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "orderDate",
                operator: "null",
                value: false,
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];

            localStorage.setItem('savedOrderFilters', JSON.stringify(params));


            const {generalSearch, orderValues, orderDate, shipMethodSearch} = params;
            console.log("params firing Order search", params);
            if (generalSearch) {
                filters.push({
                    operator: "or",
                    value: [
                        {
                            field: "id",
                            operator: "contains",
                            value: generalSearch,
                        },
                        {
                            field: "status",
                            operator: "contains",
                            value: generalSearch,
                        },
                        {
                            field: "clientName",
                            operator: "contains",
                            value: generalSearch,
                        },
                        {
                            field: "prontoOrderId",
                            operator: "contains",
                            value: generalSearch,
                        },
                    ],
                });
            }


            filters.push(
                {
                    field: "carrier",
                    operator: "contains",
                    value: shipMethodSearch,
                }
            )


            if (!orderValues) {
                //dont do anything if not selected
            } else if (orderValues === ORDER_VALUE_RANGES_0_50) {
                // picked Draft from the dropdown. We only want to show draft products. Which means we need to include the preview ones
                // and exclude anything that has a published date
                filters.push({
                    field: "orderValue",
                    operator: "lte",
                    value: 50,
                });
            } else if (orderValues === ORDER_VALUE_RANGES_50_100) {
                // picked Draft from the dropdown. We only want to show draft products. Which means we need to include the preview ones
                // and exclude anything that has a published date

                filters.push(
                    {
                        field: "orderValue",
                        operator: "gte",
                        value: 50,
                    },
                    {
                        field: "orderValue",
                        operator: "lte",
                        value: 100,
                    }
                );
            } else if (orderValues === ORDER_VALUE_RANGES_100_200) {
                // picked Published from the dropdown. We only want to show published products. Which means that we apply no filters at all here
                filters.push(
                    {
                        field: "orderValue",
                        operator: "gte",
                        value: 100,
                    },
                    {
                        field: "orderValue",
                        operator: "lte",
                        value: 200,
                    }
                );
            } else if (orderValues === ORDER_VALUE_RANGES_200_OVER) {
                // picked Published from the dropdown. We only want to show published products. Which means that we apply no filters at all here
                filters.push({
                    field: "orderValue",
                    operator: "gte",
                    value: 200,
                });
            } else {
                console.warn(
                    `Order list onSearch. Unknown Order Values selected: '${orderValues}'`
                );
            }

            //TODO timezone
            filters.push(
                {
                    field: "orderDate",
                    operator: "gte",
                    value: orderDate
                        ? dayjs(orderDate[0]).startOf("day").toISOString()
                        : undefined,
                },
                ///TODO need to be less that then start of the next day - add a day and make a less than

            );

            console.log("GTE ORDER")

            filters.push(
                {
                    field: "orderDate",
                    operator: "lt",
                    value: orderDate
                        ? dayjs(orderDate[1]).startOf("day").add(1, "day").toISOString()
                        : undefined,
                }
            );

            console.log("LTE ORDER")

            console.log(filters)

            return filters;
        },
    });

    const getNormalFilters = () => {
        const permenantFilter = [];
        permenantFilter.push({
            operator: "or",
            group: "permenant",
            value: [
                {
                    field: "carrier",
                    operator: "eq",
                    value: "shippit",
                },
                {
                    field: "carrier",
                    operator: "eq",
                    value: "optimo",
                },
                {
                    field: "carrier",
                    operator: "null",
                    value: true,
                },
            ]
        });

        // permenantFilter.push({
        //     operator: "or",
        //     group:"permenant",
        //     value: [
        //         {
        //             field: "carrier",
        //             operator: "ncontains",
        //             value: "clickncollect",
        //         },
        //         {
        //             field: "carrier",
        //             operator: "null",
        //             value: true,
        //         },
        //
        //     ]
        // });

        return permenantFilter
    }


    useEffect(() => {

        let permenantFilter = [];
        if (mode === "cnc") {
            permenantFilter.push({
                operator: "or",
                group: "permenant",
                value: [
                    {
                        field: "carrier",
                        operator: "eq",
                        value: "uber",
                    },
                    {
                        field: "carrier",
                        operator: "eq",
                        value: "clickncollect",
                    }
                ]
            });
        } else {
            permenantFilter = getNormalFilters()
        }

        setFilters(currentFilters => {

            // @ts-ignore
            const newFilters = currentFilters.filter(one => one.group !== "permenant")

            const updatedFilters = [...newFilters, ...permenantFilter]
            console.log("PERM FILTERS", mode, updatedFilters)
            return updatedFilters;
        });

        tableQueryResult.refetch();

    }, [mode]);


    const {data: tableData} = tableQueryResult

    const selectOrder = (record, selected: boolean) => {
        console.log(record, selected)
        if (selected) {
            setSelectedOrders([
                ...selectedOrders,
                record
            ])

        } else {
            setSelectedOrders(_.reject(selectedOrders, (order) => {
                return order.id === record.id
            }))
        }
    }

    useEffect(() => {
        if ((selectedOrders ?? []).length < 1) {
            setIsAllSelected(false)
        }
    }, [selectedOrders]);

    const selectAllOrders = (selected: boolean) => {
        console.log("SELECTING ALL ORDERS")

        if (selected) {
            setSelectedOrders([
                ...tableData?.data
            ])
        } else {
            setSelectedOrders([])
        }

    }

    const processSingleShippit = (record) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await sendDataToShippit(record)
                toast.promise(updateOrderMeta(record.id, 'shipment_provider', 'shippit'), {
                    loading: `Updating order shipment provider ${record.id}`,
                    success: `Updated order shipment provider ${record.id}`,
                    error: (error) => `${error}`,
                })
                    .then(() => {
                        tableQueryResult.refetch()
                    })

            } catch (e) {
                toast.error("Shippit order update error")
                reject(e)
                return
            }


            resolve();
        });
    }

    const unselectOrders = (completedOrdersId) => {
        setSelectedOrders(prevState => {
            return _.reject(prevState, (order) => {
                return _.contains(completedOrdersId, order?.id)
            })
        })
    }

    useEffect(() => {
        if (pdfInstance) {
            const blob = new Blob([pdfInstance.blob], {type: "application/pdf"});
            console.log(blob.size)
            if (blob.size > 1000) {
                saveAs(blob, "document.pdf");
            }
        }
    }, [pdfInstance]);


    useEffect(() => {
        if (pdfInstanceLabel) {
            const blob = new Blob([pdfInstanceLabel.blob], {type: "application/pdf"});
            console.log(blob.size)
            if (blob.size > 1000) {
                saveAs(blob, "label.pdf");
            }
        }
    }, [pdfInstanceLabel]);

    useEffect(() => {
        if (exportRecords == undefined) {
            return
        }
        updatePdfInstance(<PdfRenderWrapper records={exportRecords}/>)
        setExportRecords(undefined)
    }, [exportRecords]);


    useEffect(() => {
        if (exportLabelRecords == undefined) {
            console.log("Export label record undefined")
            return
        }
        updatePdfInstanceLabel(<LabelWrapper records={exportLabelRecords}/>)
        setExportLabelRecords(undefined)
    }, [exportLabelRecords]);

    const processShippit = async () => {
        // console.log(selectedOrders)
        let completedOrdersId = []

        for (let i = 0; i < selectedOrders.length; i++) {

            try {
                await toast.promise(processSingleShippit(selectedOrders[i]), {
                    loading: `Creating shipment order for ${selectedOrders[i].id}`,
                    success: `Creating shipment order success ${selectedOrders[i].id}`,
                    error: (error) => `${error}`,
                });

                completedOrdersId.push(selectedOrders[i]?.id)
            } catch (e) {

            }
        }

        //unselectOrders(completedOrdersId)
    }

    const processSingleOptimo = (record) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await sendDataToOptimoRoute(record)
                toast.promise(updateOrderMeta(record.id, 'shipment_provider', 'optimo'), {
                    loading: `Updating order shipment provider ${record.id}`,
                    success: `Updated order shipment provider ${record.id}`,
                    error: (error) => `${error}`,
                })
                    .then(() => {
                        tableQueryResult.refetch()
                    })

            } catch (e) {
                console.log(e)
                toast.error("Shippit order update error", e)
                reject(e)
                return
            }


            resolve();
        });
    }

    const processOptimo = async () => {
        // console.log(selectedOrders)
        let completedOrdersId = []

        for (let i = 0; i < selectedOrders.length; i++) {

            try {
                await toast.promise(processSingleOptimo(selectedOrders[i]), {
                    loading: `Creating shipment order for ${selectedOrders[i].id}`,
                    success: `Creating shipment order success ${selectedOrders[i].id}`,
                    error: (error) => `${error}`,
                });

                completedOrdersId.push(selectedOrders[i]?.id)
            } catch (e) {

            }
        }

        //unselectOrders(completedOrdersId)
    }

    const processPdfExportSingle = async (record) => {
        setExportRecords([record])
        try {
            await updateOrderMeta(record?.id, 'print_count', (record.meta?.print_count ?? 0) + 1)
        } catch (e) {
        }

        toast.success("Pickslip export completed")
        tableQueryResult.refetch()
    }

    const processLabelExportSingle = async (record) => {
        setExportLabelRecords([record])

        toast.success("Packlabel export completed")
        tableQueryResult.refetch()
    }

    const processPdfExport = async () => {
        let completedOrdersId = []
        let errorOrderIds = []
        setExportRecords(selectedOrders)

        for (let i = 0; i < selectedOrders.length; i++) {
            const selectedRecord = selectedOrders[i]
            try {
                await updateOrderMeta(selectedOrders[i]?.id, 'print_count', (selectedRecord.meta?.print_count ?? 0) + 1)
                completedOrdersId.push(selectedOrders[i]?.id)
            } catch (e) {
                errorOrderIds.push(selectedOrders[i]?.id)
            }
        }

        toast.success("Pickslip export completed")
        tableQueryResult.refetch()
        unselectOrders(completedOrdersId)
    }


    const processLabelExport = async () => {
        let completedOrdersId = []
        let errorOrderIds = []
        setExportLabelRecords(selectedOrders)

        for (let i = 0; i < selectedOrders.length; i++) {
            const selectedRecord = selectedOrders[i]
            try {
                // await updateOrderMeta(selectedOrders[i]?.id, 'print_count', (selectedRecord.meta?.print_count ?? 0) + 1)
                completedOrdersId.push(selectedOrders[i]?.id)
            } catch (e) {
                errorOrderIds.push(selectedOrders[i]?.id)
            }
        }

        toast.success("Packlabel export completed")
        unselectOrders(completedOrdersId)
    }

    useEffect(() => {
        console.log("IS ALL SELECTED", isAllSelected)
        selectAllOrders(isAllSelected)
    }, [isAllSelected]);

    useEffect(() => {
        console.log("mutating selectionsmap", selectedOrders)
        const mapKeys = _.pluck(selectedOrders, 'id')

        console.log(mapKeys)

        setSelectMap(_.object(mapKeys, mapKeys.map(one => true)))

        console.log(selectMap)
    }, [selectedOrders]);


    return (
        <>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <Card title="Filters">
                        <OrderFilter formProps={searchFormProps} reset={() => {
                            console.log("reset called")
                            const filters = []
                            const newFilters = getNormalFilters()
                            filters.push(
                                {
                                    field: "clientName",
                                    operator: "contains",
                                    value: "",
                                });

                            filters.push(...newFilters)

                            console.log("FILTERS RESET", filters)

                            setFilters(filters)

                            setMode("normal")
                        }}/>


                        <Fragment>

                            <SyncOrderModal
                                apiUrl={apiUrl}
                                isVisible={isModalVisible}
                                onClose={() => {
                                    setIsModalVisible(false)
                                    tableQueryResult.refetch()
                                }}
                            />

                            <Button onClick={() => setIsModalVisible(true)}>Sync Order</Button>


                            <Button htmlType="submit" type={'primary'}
                                    style={{marginLeft: '15px'}}
                                    onClick={processShippit}>
                                Process Shippit
                            </Button>

                            <Button htmlType="submit" type={'primary'}
                                    style={{marginLeft: '15px'}}
                                    onClick={processOptimo}>
                                Process Optimo
                            </Button>

                            <Button htmlType="submit" type={'default'}
                                    style={{marginLeft: '15px'}}
                                    onClick={processPdfExport}>
                                Export Pick Slips
                            </Button>

                            <Button htmlType="submit" type={'default'}
                                    style={{marginLeft: '15px'}}
                                    onClick={processLabelExport}>
                                Export Pack Labels
                            </Button>
                        </Fragment>

                    </Card>


                    <EditShippingModalForm
                        formProps={editFormProps}
                        modalProps={editModalProps}
                        queryData={editQueryResult?.data?.data}
                    />

                    <RefundModalForm
                        modalProps={refundModalProps}
                        onSuccess={handleRefundSuccess}
                    />

                    <List
                        canCreate={true}
                    >

                        <Tabs
                            type="card"
                            activeKey={mode}
                            onChange={(key) => setMode(key)}
                        >
                            <TabPane tab="Normal Orders" key="normal">
                            </TabPane>
                            <TabPane tab="CNC Orders" key="cnc">
                            </TabPane>
                        </Tabs>


                        <Table {...tableProps} rowKey="id">
                            <Table.Column<IOrder>
                                dataIndex="meta"
                                title={(<>
                                    <Checkbox checked={isAllSelected} onChange={e => {
                                        // selectOrder(record, e.target.checked)
                                        setIsAllSelected(e.target.checked)
                                    }}/>
                                </>)}
                                render={(_, record) => <>
                                    <Checkbox checked={selectMap[record.id]} onChange={e => {
                                        selectOrder(record, e.target.checked)
                                    }}/>
                                </>}
                            />

                            <Table.Column
                                dataIndex="prontoOrderId"
                                title="ProntoOderId"
                                align="center"
                                sorter
                            />

                            {/*<Table.Column dataIndex="id" title="ID" sorter/>*/}

                            <Table.Column<IOrder>
                                dataIndex="meta"
                                title="SO Status"
                                render={(_, record) => {
                                    return <>
                                        {(!!record?.meta?.shipment_provider) ? <>
                                            {/*<Badge color="green" title="Y"/>*/}
                                            <Tag color="green">{record?.meta?.shipment_provider}</Tag>
                                        </> : <>
                                            <Badge color="red" title="N"/>
                                        </>}

                                    </>
                                }}
                                align="center"
                                // sorter
                            />


                            <Table.Column
                                dataIndex="status"
                                title="Status"
                                render={(value) => <OrderStatusTagRender status={value}/>}
                                align="center"
                                sorter
                            />

                            <Table.Column<IOrder>
                                dataIndex="meta"
                                title="Printed Slip"
                                render={(_, record) => {
                                    return <>
                                        {(record?.meta?.print_count ?? 0) ? <>
                                            Y <Badge color="green" title="Y"/>
                                        </> : <>
                                            N <Badge color="red" title="N"/>
                                        </>}

                                    </>
                                }}
                                align="center"
                                // sorter
                            />

                            <Table.Column<IOrder>
                                dataIndex="clientName"
                                title="User"
                                render={(_, record) => {
                                    return record.structuredData.customerName;
                                }}
                                align="center"
                                sorter
                            />

                            <Table.Column<IOrder>
                                dataIndex="structuredData"
                                title="Ship Address"
                                render={(x, record) => {
                                    const address = (record.structuredData.shippingAddress?.addressLine1 ?? "") + ", "
                                        + (record.structuredData.shippingAddress?.addressLine2 ?? "") + ", "
                                        + record.structuredData.shippingAddress?.locality + ", " + Number(record.structuredData.shippingAddress?.postcode)
                                        + ", " + getCodeByName(record.structuredData.shippingAddress?.region)

                                    const metaAddress = record?.meta?.overridden_shipping_address
                                    const displayAddress = _.isEmpty(metaAddress) ? address : metaAddress
                                    return <>
                                        <p>{displayAddress}</p>
                                        <Button
                                            icon={<EditOutlined/>}
                                            size="small"
                                            title="show structured data"
                                            onClick={() => editModalShow(record.id.toString())}
                                        />

                                        {(record?.meta?.overridden_shipping_address ?? "") ? <>
                                            <Badge color="blue" title="Y"/>
                                        </> : <>
                                            <></>
                                        </>}
                                    </>;
                                }}
                                align="center"
                                sorter
                            />

                            <Table.Column<IOrder>
                                dataIndex="carrier"
                                title="Carrier"
                                render={(_, record) => {
                                    return record.structuredData?.shipping?.carrier ?? record.carrier;
                                }}
                                align="center"
                                sorter
                            />

                            <Table.Column
                                dataIndex="orderDate"
                                title="Order Date"
                                render={(value) => <DateField format="LLL" value={value}/>}
                                sorter
                            />
                            <Table.Column
                                dataIndex="totalValue"
                                title="Total Value"
                                sorter
                                render={(value) => {
                                    return formatCurrency(value);
                                }}
                            />


                            <Table.Column<Product>
                                title="Show"
                                dataIndex="actions"
                                render={(_, record) => (
                                    <Space>
                                        <ShowButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            title="show structured data"
                                        />
                                    </Space>
                                )}
                            />

                            <Table.Column<IOrder>
                                title="Tasks"
                                dataIndex="tasks"
                                render={(_, record) => (
                                    <>
                                        <Space>
                                            <Button
                                                type={"dashed"}
                                                size="small"
                                                onClick={() => {
                                                    processPdfExportSingle(record)
                                                }}
                                            ><PrinterOutlined/> print pick slip</Button>
                                        </Space>
                                        <Space>
                                            <Button
                                                type={"dashed"}
                                                size="small"
                                                onClick={() => {
                                                    processLabelExportSingle(record)
                                                }}
                                            ><PrinterOutlined/> print pack label</Button>
                                        </Space>

                                        <Space>
                                            <Button
                                                size="small"
                                                danger={true}
                                                onClick={() => handleOnClickRefund(record)}
                                            ><RedoOutlined/>
                                                refund
                                            </Button>
                                            {record.meta?.total_refund && (
                                                <Tag color="red">Refunded: {formatCurrency(record.meta.total_refund)}</Tag>
                                            )}

                                        </Space>


                                    </>
                                )}
                            />
                        </Table>
                    </List>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Modal {...createModalProps} title="Create a Run">
                        <Form {...createFormProps} layout="vertical">
                            <Form.Item
                                label="Run Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select options={SUBSCRIPTION_RUN_STATUSES}></Select>
                            </Form.Item>

                            <Form.Item
                                label="Run Date"
                                name="runDate"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker/>
                            </Form.Item>
                            <Form.Item
                                label="openDateTime"
                                name="openDateTime"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker/>
                            </Form.Item>
                            <Form.Item
                                label="closeDateTime"
                                name="closeDateTime"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker/>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Col>
            </Row>


        </>
    );
};

