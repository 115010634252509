import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Col,
    Divider,
    Form,
    Row,
    Input,
    Table,
    useModal,
    useForm,
    Edit,
    DeleteButton
} from "@pankod/refine-antd";
import {IResourceComponentsProps} from "@pankod/refine-core";
import {IProductBundle, Product} from "Interfaces";
import {ProductModal} from "./Components/ProductModal";
import {CodeFormItem, NameFormItem} from "./Components/FormItems";
import {PublishedProductStatusIcon} from "../Products/PublishedProductStatusIcon";

export const ProductBundleEdit: React.FC<IResourceComponentsProps> = () => {
    const {show: showProductModal, close: closeProductModal, modalProps} = useModal();
    const {formProps, saveButtonProps, form, queryResult} = useForm<IProductBundle>({
        resource: "product-bundles",
        action: "edit",
    });

    const {data} = queryResult;
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

    useEffect(() => {
        if (data) {

            console.log(data.data.products,"FROM EDIT")

            setSelectedProducts(data.data.products);
            form.setFieldsValue({products: data.data.products.map(product => product.id)});

            // Prefill quantities from quantityTable
            if (data.data.quantityTable) {
                // setQuantities(JSON.parse(data.data.quantityTable));

                if (typeof data.data.quantityTable === 'string') {
                    setQuantities(JSON.parse(data.data.quantityTable));
                } else {
                    setQuantities(data.data.quantityTable);
                }

            }
        }
    }, [data, form]);

    useEffect(() => {
        form.setFieldsValue({products: selectedProducts?.map(product => product.id)});
    }, [selectedProducts, form]);

    const handleQuantityChange = (sku: string, value: number) => {
        const newQuantities = {
            ...quantities,
            [sku]: value,
        };
        setQuantities(newQuantities);
        form.setFieldsValue({quantityTable: JSON.stringify(newQuantities)});
    };

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <ProductModal
                modalProps={modalProps}
                closeProductModal={closeProductModal}
                setSelectedIds={setSelectedProducts}
                initialSelectedIds={selectedProducts}
            />

            <Form {...formProps} layout="vertical">
                <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                    <Col>
                        <NameFormItem/>
                    </Col>
                    <Col>
                        <CodeFormItem/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Divider/>
                        <Table dataSource={selectedProducts} rowKey="id" pagination={false}>
                            <Table.Column title="SKU" dataIndex="masterSku" key="masterSku"/>
                            <Table.Column title="Name" key="name"
                                          render={(_, record: Product) => (
                                              <PublishedProductStatusIcon productProp={record}/>
                                          )}
                            />
                            {/*<Table.Column*/}
                            {/*    title="Quantity Available"*/}
                            {/*    key="quantityAvailable"*/}
                            {/*    render={(_, record: { masterSku: string }) => (*/}
                            {/*        <Input*/}
                            {/*            type="number"*/}
                            {/*            value={quantities[record.masterSku] || 1}*/}
                            {/*            onChange={(e) => handleQuantityChange(record.masterSku, Number(e.target.value))}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}

                            <Table.Column
                                title="Quantity Available"
                                key="quantityAvailable"
                                render={(_, record: { masterSku: string }) => (
                                    <Input
                                        type="number"
                                        value={quantities[record.masterSku] !== undefined ? quantities[record.masterSku] : 1}
                                        onChange={(e) => handleQuantityChange(record.masterSku, Number(e.target.value))}
                                    />
                                )}
                            />

                            <Table.Column title="Delete" dataIndex="delete" key="delete" render={(_, record: Product) => (
                                <>
                                    <DeleteButton size="small" onClick={() => {
                                        console.log(record)
                                        setSelectedProducts(selectedProducts.filter(product => product.id !== record.id));
                                    }
                                    }/>
                                </>
                            )}/>

                        </Table>
                        <Divider/>
                        <Button type="default" onClick={showProductModal}>
                            Assign Products
                        </Button>
                    </Col>
                </Row>
                <Form.Item name="products" hidden>
                    <input type="hidden"/>
                </Form.Item>
                <Form.Item name="quantityTable" hidden>
                    <input type="hidden"/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
