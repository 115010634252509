import React from "react";
import {Col, Form, Input, Modal, ModalProps, Row} from "@pankod/refine-antd";

export const RefundModalForm: React.FC<{
    modalProps: ModalProps;
    onSuccess: (values: { refundAmount: number; refundReason: string }) => void;
}> = ({ modalProps, onSuccess }) => {
    const [form] = Form.useForm();

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            onSuccess(values);
        } catch (errorInfo) {
            console.error("Validation Failed:", errorInfo);
        }
    };

    return (
        <Modal {...modalProps} title="Refund" onOk={handleOk} onCancel={modalProps.onCancel}>
            <Form form={form} layout="vertical">
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="refundAmount"
                            label="Refund Amount"
                            rules={[{ required: true, message: "Please enter the refund amount" }]}
                        >
                            <Input type="number" defaultValue={0} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="refundReason"
                            label="Refund Reason"
                            rules={[{ required: true, message: "Please enter the refund reason" }]}
                        >
                            <Input defaultValue={""} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
